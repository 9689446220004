<template>
  <base-material-card
    id="appointment-list"
    :title="$t('list', { name: $tc('appointment', 2) })"
    icon="mdi-calendar-multiselect"
  >
    <v-row align="center" class="pa-md-3" id="filters-row">
      <v-col
        cols="10"
        sm="4"
        md="2"
        :order="$vuetify.breakpoint.xsOnly ? 1 : ''"
        class="py-0 md-shorter"
      >
        <v-text-field
          outlined
          dense
          v-model="filters.search"
          prepend-inner-icon="mdi-magnify"
          class="ml-auto filters search"
          :label="$t('search')"
          hide-details
          clearable
          single-line
          @input="searchFilter"
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        :order="$vuetify.breakpoint.xsOnly ? 3 : ''"
        class="md-shorter py-0"
      >
        <v-select
          :label="$t('appointments.state')"
          outlined
          dense
          v-model="filters.state"
          class="ml-auto filters"
          hide-details
          single-line
          :items="states"
          clearable
          @input="searchFilter"
        >
          <template v-slot:item="{ item }">
            <span v-bind:style="[{ color: item.color }]">
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        :order="$vuetify.breakpoint.xsOnly ? 4 : ''"
        class="py-0"
      >
        <v-dialog v-model="others" width="600">
          <template v-slot:activator="{ on }">
            <v-btn
              :outlined="!other_filters_applied"
              block
              elevation="0"
              style="height: 30px; width: 100%"
              dense
              v-on="on"
            >
              {{ $t("other_filters") }}
              <v-btn
                icon
                color="black"
                @click.stop="clearOthers"
                v-if="other_filters_applied"
                class="internal-btn"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-btn>
          </template>
          <v-card id="others">
            <v-card-title>
              {{ $t("other_filters") }}
            </v-card-title>
            <div class="close">
              <v-btn icon @click="others = false" small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" v-if="!$store.getters['auth/isStudio']">
                  <v-select
                    v-model="filters.studio_id"
                    prepend-inner-icon="$studio"
                    :outlined="filters.studio_id"
                    :label="$tc('studio')"
                    :items="studios"
                    item-value="id"
                    item-text="studio_name"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.studio_id,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="!$store.getters['auth/isTattooer']"
                >
                  <v-autocomplete
                    v-model="filters.tattooer_id"
                    prepend-inner-icon="$tattooers"
                    :label="$tc('artist')"
                    :items="tattooers"
                    item-value="id"
                    :item-text="
                      (x) => `${x.user.first_name} ${x.user.last_name || ''}`
                    "
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.tattooer_id,
                      },
                    ]"
                    @click.stop
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6" v-if="false">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :class="[
                          {
                            text_filed_bg: applied.dates,
                          },
                        ]"
                        :value="
                          filters.dates
                            ? $d(new Date(filters.dates), 'date2digits')
                            : null
                        "
                        :label="$t('appointments.data')"
                        prepend-inner-icon="$appointments"
                        single-line
                        readonly
                        clearable
                        @click:clear="
                          filters.dates = null;
                          searchFilter();
                        "
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      no-title
                      v-model="filters.dates"
                      @input="
                        menu = false;
                        searchFilter();
                      "
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.tattoo_type,
                      },
                    ]"
                    v-model="filters.tattoo_type"
                    prepend-inner-icon="$tattoo_style"
                    :label="$t('tattooers.tattoo_type')"
                    :items="tattoo_type"
                    item-value="id"
                    item-text="name"
                    single-line
                    clearable
                    @input="searchFilter()"
                    @click.stop
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.consent_form"
                    prepend-inner-icon="$consent_form"
                    :label="$tc('consent-form.title_xs')"
                    :items="forms"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.consent_form,
                      },
                    ]"
                    multiple
                    @click.stop
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    $store.getters['auth/getSetting']('box_active') == 1 ||
                      $store.state.auth.box_active
                  "
                >
                  <v-select
                    v-model="filters.box"
                    prepend-inner-icon="$studio"
                    :label="$tc('box')"
                    :items="assignedState"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.box,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    $store.getters['auth/getSetting']('payment_appointment') ==
                      1
                  "
                >
                  <v-select
                    v-model="filters.payed"
                    prepend-inner-icon="mdi-credit-card-outline"
                    :label="$tc('tattooers.token_payment')"
                    :items="payed"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.payed,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.has_date"
                    prepend-inner-icon="$calendar"
                    :label="$tc('appointments.has_date')"
                    :items="yesNo"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.has_date,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.presuposted"
                    prepend-inner-icon="$request"
                    :label="$tc('has_presuposted')"
                    :items="yesNo"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.presuposted,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="!$store.getters['auth/isTattooer']"
                >
                  <v-select
                    v-model="filters.tattooer"
                    prepend-inner-icon="$tattooers"
                    :label="$tc('has_tattooer')"
                    :items="yesNo"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.tattooer,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.customer"
                    prepend-inner-icon="$customers"
                    :label="$tc('has_customer')"
                    :items="yesNo"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.customer,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.origin"
                    prepend-inner-icon="$origin"
                    :label="$tc('origin')"
                    :items="origins"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.origin,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="filters.reservationSistem"
                    prepend-inner-icon="$reservation_sistem"
                    :label="$tc('reservationSistem')"
                    :items="reservationSistem"
                    clearable
                    single-line
                    @input="searchFilter()"
                    dense
                    outlined
                    hide-details
                    :class="[
                      {
                        text_filed_bg: applied.reservationSistem,
                      },
                    ]"
                    @click.stop
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn outlined @click="clearOthers" height="30px">Limpiar</v-btn>
              <v-btn elevation="0" @click="others = false" height="30px">{{
                $t("apply")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="11" md="5" id="time_select" class="py-0" align="center">
        <v-btn-toggle v-model="btn" mandatory>
          <v-btn
            value="all"
            active-class="selected"
            outlined
            height="30"
            @click="day = null"
          >
            {{ $t("all") }}
          </v-btn>
          <v-menu
            v-model="menu.day"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                value="day"
                active-class="selected"
                outlined
                height="30"
                >{{ $t("day") }}</v-btn
              >
            </template>
            <v-date-picker
              first-day-of-week="1"
              no-title
              v-model="day"
              @input="menu.day = false"
              color="primary"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="menu.week"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                value="week"
                active-class="selected"
                outlined
                height="30"
                >{{ $t("week") }}</v-btn
              >
            </template>
            <v-date-picker
              first-day-of-week="1"
              no-title
              v-model="week"
              @input="menu.week = false"
              color="primary"
              range
              class="weekSelector"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu.month"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                value="month"
                active-class="selected"
                outlined
                height="30"
              >
                {{ $t("month") }}
              </v-btn>
            </template>
            <v-date-picker
              first-day-of-week="1"
              no-title
              v-model="month"
              @input="menu.month = false"
              type="month"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-btn-toggle>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.xsOnly && false"></v-spacer>
      <v-col>
        <template v-if="$store.getters['auth/isTattooer']">
          <v-menu>
            <template v-slot:activator="{ on: onDialog }">
              <v-btn
                v-if="studios.length"
                icon
                v-on="onDialog"
                @click="studio_selected = null"
                :class="$vuetify.breakpoint.xsOnly ? 'order-2' : ''"
                class="mr-2"
              >
                <v-icon size="36">mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                @click="$alert($t('no_studios'))"
                :class="$vuetify.breakpoint.xsOnly ? 'order-2' : ''"
              >
                <v-icon size="36">mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-dialog width="400" v-model="select_studio_dialog">
                <template v-slot:activator="{ on: onDialog }">
                  <v-list-item v-on="onDialog">
                    <v-list-item-icon class="mr-3 my-md-2 my-2">
                      <v-icon small>$request</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("new", { name: $tc("appointment") }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-card>
                  <v-card-title>{{
                    studio_selected
                      ? $t("select_appoinment_type")
                      : $t("select_studio")
                  }}</v-card-title>
                  <div class="close">
                    <v-btn icon @click="select_studio_dialog = false" small>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text>
                    <template v-if="!studio_selected">
                      <v-row align="center" justify="center">
                        <v-col v-for="s in studios2" :key="s.id" cols="6">
                          <v-card
                            @click="checkTime(s)"
                            class="pointer"
                            style="border: 1px solid var(--v-primary-base)"
                          >
                            <v-card-title
                              cols="10"
                              style="
                                font-size: 18px !important;
                                text-decoration: none !important;
                              "
                              class="text-center"
                            >
                              {{ s.studio_name }}
                            </v-card-title>

                            <v-avatar size="50" class="mx-auto d-block">
                              <img :src="image(s)" :alt="s.studio_name" />
                            </v-avatar>
                            <v-card-actions></v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row v-else>
                      <template v-for="(type, i) in services">
                        <v-col cols="4" :key="`col-${i}`" class="px-1">
                          <v-card
                            @click="formStudio(studio_selected, type)"
                            style="border: 1px solid var(--v-primary-base)"
                          >
                            <v-card-title
                              class="mb-3 px-0 d-block text-center"
                              style="
                                font-size: 18px !important;
                                text-decoration: none !important;
                              "
                            >
                              {{
                                type.artist_type_id
                                  ? $t("appointments.type." + type.name)
                                  : type.name
                              }}
                            </v-card-title>

                            <v-avatar
                              color="primary"
                              size="100"
                              class="gris3--text text-h2 text-uppercase mx-auto"
                              style="display: flex"
                            >
                              {{ (type !== undefined ? type.name : type)[0] }}
                            </v-avatar>

                            <v-card-subtitle></v-card-subtitle>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                  <v-card-actions></v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog width="300" v-model="dialog">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-icon class="mr-3 my-md-2 my-2">
                      <v-icon small>mdi-link-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("appointments.link") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-card id="pedirCitaLink">
                  <v-card-title
                    style="
                      text-decoration: underline;
                      text-transform: uppercase;
                    "
                  >
                    Link pedir cita:
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialog = false" small>
                      <v-icon id="iconClose">mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text>
                    <v-text-field
                      outlined
                      dense
                      id="URL"
                      readonly
                      v-model="URL"
                    >
                      <template slot="prepend-inner">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon class="mr-2" v-on="on" @click="copy()">
                              mdi-content-copy
                            </v-icon>
                          </template>
                          {{ $t("copy") }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list>
          </v-menu>
        </template>
      </v-col>
      <template
        v-if="
          (!timetableCheck ||
            $store.getters['auth/getLaborExange'] === null ||
            $store.getters['auth/getSetting']('policy_appointment') === null ||
            ($store.getters['auth/getSetting']('policy_appointment') ===
              'link' &&
              $store.getters['auth/getSetting']('link_appointment') === null) ||
            ($store.getters['auth/getSetting']('policy_appointment') ===
              'page' &&
              $store.getters['auth/getSetting']('page_policy_appointment') ===
                null)) &&
            $store.getters['auth/isStudio']
        "
      >
        <v-btn
          icon
          @click="alert()"
          :class="$vuetify.breakpoint.xsOnly ? 'order-2' : ''"
        >
          <v-icon size="36" v-on="on"> mdi-plus-circle </v-icon>
        </v-btn>
      </template>

      <template v-else-if="$store.getters['auth/isStudio']">
        <v-menu>
          <template v-slot:activator="{ on: menu }">
            <v-btn
              elevation="0"
              icon
              :class="$vuetify.breakpoint.xsOnly ? 'order-2' : ''"
              v-on="menu"
            >
              <v-icon size="36" v-on="on"> mdi-plus-circle </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="false"
              @click="
                $router.push({
                  name: 'customer_inscription_studio_pre',
                  params: { token: $store.state.auth.user.studio.token },
                })
              "
            >
              <v-list-item-icon class="mr-3 my-md-2 my-2">
                <v-icon small> $request </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("tattooers.inscription") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-dialog width="530" v-model="dialog_new">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on" @click="type = null">
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>$request</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        $t("new", { name: $tc("appointment") })
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title
                  style="text-decoration: underline; text-transform: uppercase"
                >
                  Tipo de cita
                </v-card-title>

                <div class="close">
                  <v-btn icon @click="dialog_new = false" small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-row v-if="!type" justify="center">
                    <template v-for="(s, i) in services">
                      <v-col cols="4" :key="`col-${i}`" class="mx-2">
                        <v-card
                          @click="select(s)"
                          style="border: 1px solid var(--v-primary-base)"
                        >
                          <v-card-title
                            class="mb-2 px-0 d-block text-center"
                            style="
                                font-size: 16px !important;
                                text-decoration: none !important;
                              "
                          >
                            {{
                              s.artist_type_id
                                ? $t("appointments.type." + s.name)
                                : s.name
                            }}
                          </v-card-title>

                          <v-avatar
                            color="primary"
                            size="60"
                            class="gris3--text text-h2 text-uppercase mx-auto"
                            style="display: flex"
                          >
                            {{ s.name[0] }}
                          </v-avatar>

                          <v-card-subtitle></v-card-subtitle>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                  <v-row v-else justify="center">
                    <template v-for="(s, i) in sub_types">
                      <v-col cols="6" :key="`col2-${i}`" class="px-1">
                        <v-card
                          @click="selectSub(s.description)"
                          style="border: 1px solid var(--v-primary-base)"
                        >
                          <v-card-title
                            class="px-0 d-block text-center"
                            style="
                              font-size: 18px !important;
                              text-decoration: none !important;
                            "
                          >
                            {{ s.description }}
                          </v-card-title>
                          <!--
                            <v-avatar
                              color="primary"
                              size="100"
                              class="gris3--text text-h2 text-uppercase mx-auto"
                              style="display: flex"
                            >
                              {{ s.description[0] }}
                            </v-avatar>
                            -->
                          <v-card-subtitle></v-card-subtitle>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog width="400" v-model="dialog">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>mdi-link-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("appointments.link") }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card id="pedirCitaLink">
                <v-card-title
                  style="text-decoration: underline; text-transform: uppercase"
                >
                  {{ $t("link_online_appointment") }}
                </v-card-title>
                <v-card-subtitle class="mt-2 primary--text">
                  {{ $t("description_online_link") }}
                </v-card-subtitle>
                <div class="close">
                  <v-btn icon @click="dialog = false" small>
                    <v-icon id="iconClose">mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-text-field
                    outlined
                    dense
                    id="URL"
                    readonly
                    v-model="URL"
                    hide-details
                  >
                    <template slot="prepend-inner">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" @click="copy()">
                            mdi-content-copy
                          </v-icon>
                        </template>
                        {{ $t("copy") }}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    style="
                      width: 100px !important;
                      color: #363533;
                      height: 25px;
                      padding-top: 10px !important;
                    "
                    @click="sendTo()"
                    elevation="0"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
                    {{ $t("enviar", { name: $t("") }) }}
                  </v-btn>
                  <SendMail ref="sendMail" hidden special />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </v-menu>
      </template>
    </v-row>

    <base-data-table-cards
      id="appointment_table"
      :headers="headers"
      :headers_small="headers_small"
      :data="items"
      :title="$tc('appointment', 1)"
      :options.sync="options"
      :server-items-length="totalAppointments"
      :totalItems="totalAppointments"
      :sort-by.sync="sortBy"
    >
      <template v-slot:item_actions="item" v-if="$vuetify.breakpoint.mdAndUp">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              :color="item.status === 'inscription' ? '' : ''"
              v-bind="attrs"
              large
            >
              mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list>
            <!-- <v-list-item
                class="apListItem"
                @click="
                  $router.push({
                    name: 'ConsentFormViewNoId',
                  })
                "
                ><v-icon class="mr-2" small>$consent_form</v-icon>
                {{ $t("consent-form.title_new") }}</v-list-item
              >
               <v-list-item class="apListItem"
                ><v-icon class="mr-2" small>$camera</v-icon
                >{{ $t("consent-form.photo") }}</v-list-item
              >-->
            <v-list-item
              class="apListItem"
              v-on="on"
              @click="openAppointment(item.id)"
              ><v-icon class="mr-2" small style="margin-top: 0px !important"
                >$eye</v-icon
              ><span>{{
                $t("view", { name: $tc("appointment") })
              }}</span></v-list-item
            >
            <!--
              <v-list-item
                v-if="item.type && item.tattooer"
                class="apListItem"
                @click="
                  item.type == 'tattooer'
                    ? $router.push({
                        name: 'ConsentFormView',
                        params: { id: item.id },
                      })
                    : $router.push({
                        name: 'ConsentFormPiercerView',
                        params: { id: item.id },
                      })
                "
                >
                <v-icon class="mr-2" small style="margin-top: -0px !important"
                  >$consent_form</v-icon
                >
                {{ $t("consent-form.title_xs") }}
                </v-list-item>
              <v-list-item class="apListItem" v-on="on"
                ><v-icon class="mr-2" small>$editprofile</v-icon>
                <span>{{
                  $t("edit", { name: $tc("appointment") })
                }}</span></v-list-item
              >-->

            <LaborExangeDayAssaing
              v-if="
                !item.available_dates.find(
                  (x) =>
                    x.type === 'appointment_day' ||
                    x.type === 'appointment_day_session'
                ) && item.tattooer
              "
              style="cursor: pointer"
              @update="fetchAppointments"
              :appointment="item"
            />
            <v-list-item class="apListItem" @click="del(item)"
              ><v-icon
                class="mr-2"
                small
                style="margin-top: -0px !important"
                v-if="!canDelete(item)"
                >$files</v-icon
              >
              <v-icon
                v-else
                class="mr-2"
                small
                style="margin-top: -0px !important"
                >$delete</v-icon
              >
              <span v-if="canDelete(item)">{{
                $t("delete", { name: $tc("") })
              }}</span>
              <span v-else-if="statusAppointment(item).status !== 'archived'">{{
                $t("archivate")
              }}</span>
              <span v-else>{{ $t("desarchivate") }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item_actions="item" v-else>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="primary"
              fab
              dark
              outlined
              small
              v-on="on"
              @click="openAppointment(item.id)"
            >
              <v-icon>$eye</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              @click="del(item)"
              fab
              outlined
              dark
              small
              v-if="!canDelete(item)"
              >$files</v-btn
            >
            <v-btn
              color="primary"
              @click="del(item)"
              fab
              outlined
              dark
              small
              v-else
            >
              <v-icon>$delete</v-icon></v-btn
            >
          </v-col></v-row
        >
      </template>

      <template v-slot:item_customer="item"
        >{{ item.customer.user.first_name || "-" }}
        {{ item.customer.user.last_name || "" }}
        <WhatsApp :phone_number="item.customer.user.phone" />

        <span v-if="item.extra_customers && item.extra_customers.length > 1">
          +{{ item.extra_customers.length - 1 }}
        </span>
      </template>
      <template v-slot:item_tattooer="item">
        <span v-if="item.tattooer && item.tattooer.user.first_name"
          >{{ item.tattooer.user.first_name }} {{ item.tattooer.user.last_name
          }}<v-icon color="red" v-if="!isActive(item.tattooer)">
            mdi-exclamation
          </v-icon>
          <span v-if="item.extra_tattooers && item.extra_tattooers.length > 1">
            +{{ item.extra_tattooers.length - 1 }}
          </span>
          <span class="text-uppercase" v-if="item.type">
            - {{ item.type[0] }}
          </span>
        </span>

        <span v-else>-</span>
      </template>
      <template v-slot:item_studio="item">
        <span v-if="item.studio.studio_name">{{
          item.studio.studio_name
        }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item_date="item">
        <div
          v-if="
            item.available_dates.length == 2 &&
              item.available_dates[1].type == 'labor_exange_end' &&
              item.available_dates[0].type == 'labor_exange_start'
          "
        >
          <span
            v-if="
              item.available_dates[0].date.substring(8, 10) == '01' &&
                (item.available_dates[1].date.substring(8, 10) == '31' ||
                  item.available_dates[1].date.substring(8, 10) == '30' ||
                  item.available_dates[1].date.substring(8, 10) == '28' ||
                  item.available_dates[1].date.substring(8, 10) == '29')
            "
            style="text-transform: uppercase"
            v-bind:class="[
              {
                'red--text':
                  new Date(
                    item.available_dates[1].date.substring(0, 10)
                  ).getTime() <= new Date().getTime(),
              },
            ]"
            >{{
              $d(
                new Date(item.available_dates[0].date.substring(0, 10)),
                "month"
              )
            }}
          </span>
          <span v-else>
            {{
              $d(
                new Date(item.available_dates[0].date.substring(0, 10)),
                "dayMonth"
              )
            }}
            -
            {{
              $d(
                new Date(item.available_dates[1].date.substring(0, 10)),
                "dayMonth"
              )
            }}
          </span>
        </div>
        <span
          v-else-if="
            item.available_dates.length > 0 &&
              (item.available_dates[0].type == 'appointment_day' ||
                item.available_dates[0].type == 'appointment_day_session')
          "
          >{{
            $browserDetect.isSafari || $browserDetect.meta.name === "Safari"
              ? $d(
                  new Date(item.available_dates[0].date.substring(0, 10)),
                  "date2digits"
                ) +
                " " +
                item.available_dates[0].date.substring(11, 16)
              : $d(new Date(item.available_dates[0].date), "short")
          }}</span
        >
        <span
          v-else-if="
            item.available_dates.length > 0 &&
              item.available_dates[0].type == 'labor_exange_start'
          "
          >{{
            $browserDetect.isSafari || $browserDetect.meta.name === "Safari"
              ? $d(
                  new Date(item.available_dates[2].date.substring(0, 10)),
                  "date2digits"
                ) +
                " " +
                item.available_dates[2].date.substring(11, 16)
              : $d(new Date(item.available_dates[2].date), "short")
          }}
        </span>
        <span v-else>
          <add-session
            v-if="
              !item.available_dates.find(
                (x) =>
                  x.type === 'appointment_day' ||
                  x.type === 'appointment_day_session'
              )
            "
            class="pointer"
            :text="true"
            :not_outlined="true"
            @update="fetchAppointments"
            :appointment="item"
            :customers="item.extra_customers"
          />
        </span>
      </template>
      <template v-slot:item_price="item">{{ finalPrice(item) }}</template>
      <template v-slot:item_debit_customer="item">{{
        pendingCustomer(item)
      }}</template>
      <template v-slot:item_state="item">
        <v-menu :disabled="!item.state.pending">
          <template v-slot:activator="{ on }">
            <v-chip
              class="state"
              :class="$vuetify.breakpoint.smAndDown ? 'mr-4' : ''"
              :color="statusColor(item.state.text)"
              v-on="on"
            >
              {{ $t(`appointments.states.${item.state.text}`) }}
              <v-icon color="black" right small v-if="item.state.pending"
                >mdi-information</v-icon
              >
            </v-chip>
          </template>
          <v-list>
            <v-list-item v-if="pendingSetting.tattooer">
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.tattooer">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Artista</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.date">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Fecha</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.payed">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Pagado</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="
                pendingSetting.box &&
                  $store.getters['auth/getSetting']('box_active') == '1'
              "
            >
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.box">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Box</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="pendingSetting.presupuesto">
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.presupost">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Presupuesto</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="pendingSetting.token_payment">
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.token_payment">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Fecha</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="pendingSetting.consent">
              <v-list-item-icon class="my-1 mr-1">
                <v-icon color="red" v-if="item.state.info.consent">
                  mdi-close
                </v-icon>
                <v-icon color="success" v-else> mdi-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> F. Consentimeinto</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item_clientArtist="item">
        <div col="2">
          <b>{{ item.customer.user.fullname }}</b>
        </div>
        <div v-if="item.tattooer != null">
          <em>{{ item.tattooer.user.fullname }}</em>
        </div>
        <div v-else>Selecciona un artista</div>
      </template>
      <template v-slot:item_price_debt="item" justify="center">
        <div align="center">
          <b>{{ finalPrice(item) }}</b>
        </div>
        <div align="center">
          {{ pendingCustomer(item) }}
        </div>
      </template>
      <template v-slot:item_phone="item">
        <WhatsApp :phone_number="item.customer.user.phone" large class="mr-4" />
      </template>
      <template v-slot:item_state_small="item">
        <v-avatar
          class="ml-4"
          :color="statusColor(item.state.text)"
          :size="40"
          v-on="on"
          style="
            margin: 0 auto;

            text-transform: uppercase;
            color: #272727;
            cursor: pointer;
          "
        >
          <span style="font-size: 25px">
            {{ $t(`appointments.states.${item.state.text}`)[0] }}
          </span>
        </v-avatar>
      </template>
    </base-data-table-cards>
  </base-material-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as moment from "moment";
import { debounce } from "lodash";

export default {
  name: "AppointmentList",
  components: {
    LaborExangeDayAssaing: () =>
      import("@/components/appointments/LaborExangeDayAssaing"),
    AddSession: () => import("@/components/appointments/sessions/Add"),
    SendMail: () => import("@/components/mails/SendMail"),
  },
  watch: {
    options: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  data() {
    return {
      applied: {
        dates: false,
        tattoo_type: false,
        consent_form: false,
        box: false,
        payed: false,
        presuposted: false,
        tattooer: false,
        customer: false,
        reservationSistem: false,
        origin: false,
        has_date: false,
        studio_id: false,
        tattooer_id: false,
      },
      accion: "send",
      dialog_new: false,
      menu: {
        month: false,
        day: false,
        week: false,
      },
      tattoo_type: [],
      items: null,
      services: [],
      itemsServices: [],
      assignedState: [
        { text: this.$t("assigned"), value: true },
        { text: this.$t("not_assigned"), value: false },
      ],
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      forms: [
        { text: this.$t("appointments.states.pending"), value: "pending" },
        { text: this.$t("appointments.states.complete"), value: "completed" },
        {
          text: this.$t("appointments.states.customer_completed"),
          value: "customer_completed",
        },
        {
          text: this.$t("appointments.states.artist_completed"),
          value: "artist_completed",
        },
        {
          text: this.$t("appointments.states.studio_completed"),
          value: "studio_completed",
        },
        {
          text: this.$t("appointments.states.customer_incompleted"),
          value: "customer_incompleted",
        },
        {
          text: this.$t("appointments.states.artist_incompleted"),
          value: "artist_incompleted",
        },
        {
          text: this.$t("appointments.states.studio_incompleted"),
          value: "studio_incompleted",
        },
      ],
      origins: [
        { text: this.$t("calendar"), value: "calendar" },
        { text: this.$t("web"), value: "web" },
        { text: this.$t("internal"), value: "internal" },
      ],
      reservationSistem: [
        { text: this.$t("asap"), value: "asap" },
        { text: this.$t("day_time"), value: "day_time" },
        { text: this.$t("weekly"), value: "week" },
        { text: this.$t("month"), value: "month" },
      ],
      payed: [
        { text: this.$t("token_payments.done"), value: true },
        { text: this.$t("token_payments.not_done"), value: false },
      ],
      studios: [],
      studios2: [],
      studio_selected: null,
      types: [],
      type_id: null,
      select_studio_dialog: false,
      studio: [],
      timetableCheck: false,
      tattooers: [],
      totalAppointments: 0,
      sortBy: "first_name",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      dialog: false,
      min: null,
      today: null,
      max: null,
      others: false,
      states: [
        {
          text: this.$t("appointments.states.request"),
          value: "request",
          color: "var(--v-primary-base)",
        },
        {
          text: this.$t("appointments.states.help"),
          value: "help",
          color: "green",
        },
        {
          text: this.$t("appointments.states.pending"),
          value: "pending",
          color: "orange",
        },
        {
          text: this.$t("appointments.states.assigned"),
          value: "assigned",
          color: "var(--v-secondary-base)",
        },

        {
          text: this.$t("appointments.states.check_out"),
          value: "check_out",
          color: "var(--v-gris1-base)",
        },
        {
          text: this.$t("appointments.states.archived"),
          value: "archived",
          color: "red",
        },
      ],
      type: null,
      sub_types: [],
      servicesTattooer: [],
      headers_small: [
        { value: "state_small", no_col: true },
        { value: "clientArtist", cols: 4 },
        { value: "price_debt" },
        { value: "phone", no_col: true },
      ],
    };
  },

  mounted() {
    if (this.$store.getters["auth/isStudio"]) this.fetchService();
    if (this.$route.params.from) this.filters.from = this.$route.params.from;
    if (this.$route.params.to) this.filters.to = this.$route.params.to;

    if (this.$route.params.not_payed) this.filters.payed = false;
    if (this.$route.params.total) {
      this.filters.has_date = true;
    }
    if (this.$route.params.pending) {
      this.filters.state = "pending";
    }
    this.fetchAppointmentsFilters();
    if (
      this.$store.getters["auth/isTattooer"] &&
      this.filters.tattooer_id !== this.$store.state.auth.user.tattooer.id
    ) {
      this.filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.filters.studio_id = null;
    } else if (
      this.$store.getters["auth/isStudio"] &&
      this.filters.studio_id !== this.$store.state.auth.user.studio.id
    ) {
      this.filters.studio_id = this.$store.state.auth.user.studio.id;
      this.filters.tattooer_id = null;
    }

    if (this.$store.getters["auth/isTattooer"]) {
      this.fetchTypes();
      this.fetchTattooerStudios();
    } else if (this.$store.getters["auth/isStudio"]) {
      this.fetchStudio();
    }
    this.today = moment().format("YYYY-MM-DD");

    if (this.$route.params.request) {
      this.filters.state = "check_out";
      this.day = null;
    } else if (!this.filters.from) {
      this.day = moment().format("YYYY-MM-DD");
    }
  },
  computed: {
    pendingSetting() {
      let a = this.$store.getters["auth/getSetting"]("pending_setting");
      if (typeof a == "string") a = JSON.parse(a);
      return a;
    },
    month: {
      get() {
        if (this.filters.from) {
          let split = this.filters.from.split("-");
          return `${split[0]}-${split[1]}`;
        }
        return null;
      },
      set(value) {
        value = value.split("-");
        let from = new Date(value[0], value[1] - 1, 1);
        this.filters.from = `${from.getFullYear()}-${
          from.getMonth() + 1 >= 10
            ? +from.getMonth() + 1
            : "0" + (+from.getMonth() + 1)
        }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`;

        let to = new Date(value[0], value[1], 0);
        this.filters.to = `${to.getFullYear()}-${
          to.getMonth() + 1 >= 10
            ? +to.getMonth() + 1
            : "0" + (+to.getMonth() + 1)
        }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`;

        this.searchFilter();
      },
    },
    week: {
      get() {
        //return [];
        if (this.filters.from) return [this.filters.from, this.filters.to];
        return null;
      },
      set(value) {
        value = value[0];
        value = new Date(value);
        let d = value.getDate() + 1;
        let di = value.getDay() === 0 ? 7 : value.getDay();
        value.setDate(d - di);
        this.filters.from = `${value.getFullYear()}-${
          value.getMonth() + 1 >= 10
            ? value.getMonth() + 1
            : "0" + (value.getMonth() + 1)
        }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`;

        value.setDate(value.getDate() + 6);

        this.filters.to = `${value.getFullYear()}-${
          value.getMonth() + 1 >= 10
            ? value.getMonth() + 1
            : "0" + (value.getMonth() + 1)
        }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`;

        this.searchFilter();
      },
    },
    day: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.filters.from = value;
        this.filters.to = value;

        this.searchFilter();
      },
    },

    btn() {
      if (this.filters.from && this.filters.to) {
        var date1 = new Date(this.filters.from);
        var date2 = new Date(this.filters.to);

        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if (Difference_In_Days === 0) return "day";
        else if (Difference_In_Days === 6) return "week";
        else return "month";
      }
      return "all";
    },

    other_filters_applied() {
      let keys = Object.keys(this.filters);

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let filter = this.filters[key];
        console.log(key, filter);

        if (
          filter !== "undefined" &&
          filter !== undefined &&
          filter !== "" &&
          filter !== null
        ) {
          switch (key) {
            case "dates":
            case "tattoo_type":
            case "consent_form":
            case "box":
            case "payed":
            case "presuposted":
            case "tattooer":
            case "customer":
            case "reservationSistem":
            case "origin":
            case "has_date":
              return true;
            case "studio_id":
              if (!this.$store.getters["auth/isStudio"]) return true;
              break;
            case "tattooer_id":
              if (!this.$store.getters["auth/isTattooer"]) return true;
              break;
          }
        }
      }
      return false;
    },
    ...mapState("appointments", ["filtersState"]),
    filters: {
      get() {
        return this.filtersState;
      },
      set(value) {
        this.$store.commit("appointments/SET_FILTERS")(value);
      },
    },
    URL() {
      if (this.$store.getters["auth/isTattooer"])
        return `${window.location.origin}/?#/inscription/tattooer/${this.$store.state.auth.user.tattooer.token}/appointment/pre`;
      if (this.$store.getters["auth/isStudio"])
        return `${window.location.origin}/?#/inscription/studio/${this.$store.state.auth.user.studio.token}/appointment/pre`;
    },
    headers() {
      if (this.$store.getters["auth/isTattooer"])
        return [
          {
            text: this.$tc("customer"),
            align: "center",
            sortable: false,
            value: "customer",
          },
          {
            text: this.$tc("studio"),
            align: "center",
            sortable: false,
            value: "studio",
          },
          /*
          {
            text: this.$tc("service"),
            align: "center",
            sortable: false,
            value: "service",
          },
          */
          {
            text: this.$t("appointments.price"),
            align: "center",
            sortable: false,
            value: "price",
          },
          {
            text: this.$t("appointments.date"),
            align: "center",
            sortable: false,
            value: "date",
          },
          {
            text: this.$t("tattooers.state"),
            sortable: false,
            value: "state",
            align: "center",
          },
          {
            text: this.$t("actions"),
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      if (this.$store.getters["auth/isStudio"])
        return [
          {
            text: this.$tc("customer"),
            align: "center",
            sortable: false,
            value: "customer",
          },
          {
            text: this.$tc("artist"),
            align: "center",
            sortable: false,
            value: "tattooer",
          },
          /*
          {
            text: this.$tc("service"),
            align: "center",
            sortable: false,
            value: "service",
          },
          */
          {
            text: this.$t("appointments.price"),
            align: "center",
            sortable: false,
            value: "price",
          },
          {
            text: this.$t("expenses.pendings"),
            align: "center",
            sortable: false,
            value: "debit_customer",
          },
          {
            text: this.$t("appointments.date"),
            align: "center",
            sortable: false,
            value: "date",
          },
          {
            text: this.$t("tattooers.state"),
            sortable: false,
            value: "state",
            align: "center",
          },
          {
            text: this.$t("actions"),
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      if (this.$store.getters["auth/isAdmin"])
        return [
          {
            text: this.$tc("customer"),
            sortable: false,
            value: "customer",
          },
          {
            text: this.$tc("tattooer"),
            sortable: false,
            value: "tattooer",
          },
          {
            text: this.$tc("studio"),
            sortable: false,
            value: "studio",
          },
          {
            text: this.$t("appointments.price"),
            sortable: false,
            value: "price",
          },
          {
            text: this.$t("appointments.date"),
            sortable: false,
            value: "date",
          },
          {
            text: this.$t("tattooers.state"),
            sortable: false,
            value: "state",
            align: "center",
          },
          {
            text: this.$t("actions"),
            sortable: false,
            value: "actions",
          },
        ];
    },
  },
  methods: {
    sendTo() {
      this.$refs.sendMail.open("NEW_ONLINE_DATE");
    },
    nameAvatar(item) {
      //console.log(arr);
      let arr = item.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });

      return nom;
    },
    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({}).then((response) => {
        this.services = response.data;
      });
    },
    fetchServiceTattooer() {
      console.log(this.studio_selected);
      this.getServices({
        filters: { studio_id: this.studio_selected.id },
      }).then((response) => {
        this.services = response.data;
      });
    },
    pendingCustomer(item) {
      let pending = 0;
      item.available_dates.forEach((d, i) => {
        if (!this.liq(d)) {
          pending += d.sesion_price;
          if (i === 0) pending -= item.token_payment;
        }
      });
      if (!item.payed) pending += item.token_payment;
      if (pending == 0) return "-";
      return this.$n(pending, "currency");
    },
    liq(item) {
      let liq = false;
      if (item.expenses.length) {
        item.expenses.forEach((ex) => {
          if (ex.pending == 0) {
            liq = true;
          }
        });
      }
      return liq;
    },
    isActive(t) {
      let today = new Date().getTime();
      let a = t.tattooer_studios.find((ts) => {
        let to = new Date(ts.to).getTime();
        let from = new Date(ts.from).getTime();
        return (
          from <= today &&
          (ts.to === null || to > today) &&
          ts.studio_id === this.$store.state.auth.user.studio.id
        );
      });
      if (a) return true;
      return false;
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      console.log("fetch");
      this.getTypeArtist().then((tattooers) => {
        this.itemsServices = tattooers.data;
        if (this.$store.state.auth.user.types[0].name) {
          this.types = this.$store.state.auth.user.types;
        } else {
          console.log("NO OBJECT");
          this.types = this.$store.state.auth.user.types.map((q) => {
            return this.itemsServices.find((x) => x.id === q);
          });
        }
      });
    },
    canDelete(a) {
      console.log("CAN DELETE");
      console.log(a);
      if (a.payed) return false;
      let can = true;
      if (a.available_dates)
        a.available_dates.forEach((d) => {
          console.log("d", d);
          d.expenses.forEach((e) => {
            console.log("e", e);
            if (e.stripe_token || e.pending == false) can = false;
          });
        });

      return can;
    },
    clearOthers() {
      let keys = Object.keys(this.filters);

      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let filter = this.filters[key];
        console.log(key, filter);

        switch (key) {
          case "dates":
          case "tattoo_type":
          case "consent_form":
          case "box":
          case "payed":
          case "presuposted":
          case "tattooer":
          case "customer":
          case "reservationSistem":
          case "origin":
          case "has_date":
            this.filters[key] = undefined;
            break;
          case "studio_id":
            if (this.$store.getters["auth/isTattooer"])
              this.filters[key] = undefined;
            break;
          case "tattooer_id":
            if (this.$store.getters["auth/isStudio"])
              this.filters[key] = undefined;
            break;
        }
        this.others = false;
      }
      this.searchFilter();
    },
    image(studio) {
      console.log("s", studio);

      let img = studio.user.setting.find((x) => x.key === "profile_image");
      if (!img) {
        img = {
          url: require("@/assets/logo.svg"),
        };
      }

      return img.url;
    },
    ...mapActions("tattooers", ["getTattooerStudiosByDate"]),
    fetchTattooerStudios() {
      this.getTattooerStudiosByDate({
        tattooer_id: this.$store.state.auth.user.tattooer.id,
        date: this.$d(new Date(), "date2digits"),
      }).then((data) => {
        this.studios2 = data
          .filter((x) => {
            if (x.limitations) {
              return x.limitations.appointment_new;
            }
            return true;
          })
          .map((x) => x.studio);
      });
    },
    checkTime(studio) {
      let has_time = false;
      studio.timetable.forEach((x) => {
        if (x.length > 0) has_time = true;
      });
      if (has_time) {
        this.studio_selected = studio;
        this.fetchServiceTattooer();
      } else {
        this.$alert(this.$t("studio_selected_no_time"));
      }
    },
    formStudio(studio, type) {
      this.internalForm({
        studio_id: studio.id,
        type: type.name,
        type_id: type.id,
      }).then((response) => {
        if (response.success)
          this.$router.push({
            name: "appointmentCreatingView",
            params: { id: response.id },
          });
      });
    },
    finalPrice(appoinment) {
      let price = 0;
      appoinment.available_dates.forEach((x) => {
        if (
          x.type === "appointment_day" ||
          x.type === "appointment_day_session"
        ) {
          price += x.sesion_price;
        }
      });
      if (price != 0) return this.$n(price, "currency");
      return "-";
    },
    ...mapActions("studios", ["getStudio"]),
    ...mapActions("appointments", [
      "getAppointments",
      "getAppointmentFilters",
      "getMinMaxDates",
      "deleteOrArchivate",
      "internalForm",
    ]),
    ...mapActions("subservices", ["getSubServices"]),
    select(type) {
      this.getSubServices({
        filters: {},
        pagination: { itemPerPage: -1 },
        service_id: type.id,
      }).then((response) => {
        this.sub_types = response.data;
        this.type = type.name;
        this.type_id = type.id;
        if (this.sub_types.length == 0) this.selectSub("");
      });
    },
    selectSub(sub_type) {
      console.log("SELECTSUB", sub_type);
      this.internalForm({
        studio_id: this.$store.state.auth.user.studio.id,
        type: this.type,
        type_id: this.type_id,
        sub_type,
      }).then((response) => {
        console.log("FORMS", response);
        if (response.success)
          this.$router.push({
            name: "appointmentCreatingView",
            params: { id: response.id },
          });
      });
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    fetchAppointments() {
      this.loading = true;
      this.getAppointments({
        pagination: this.options,
        filters: this.filters,
      }).then((appointments) => {
        this.items = appointments.data;
        console.log(this.items);
        this.totalAppointments = appointments.total;
        this.loading = false;
        Object.keys(this.applied).forEach((key) => {
          this.applied[key] =
            this.filters[key] !== undefined && this.filters[key] !== null;
        });
      });
    },
    datesWeek(item) {
      console.log(item);
      if (item.id) {
        this.getMinMaxDates(item.id).then((data) => {});
      }
      /**/
    },
    fetchStudio() {
      console.log("fetching");
      this.loading = true;
      this.getStudio(this.$store.state.auth.user.studio.id).then((studio) => {
        this.timetableCheck = false;
        studio.timetable.forEach((element) => {
          if (element.length > 0) this.timetableCheck = true;
        });
        console.log(this.timetableCheck);
      });
    },
    alert() {
      if (
        !this.timetableCheck &&
        this.$store.getters["auth/getLaborExange"] === null
      ) {
        this.$confirm(this.$t("appointments.info_both"), "", "").then(() => {
          this.$router.push({
            name: "UserSettings",
            params: { type: "alerts", calendar: true },
          });
        });
      } else if (this.$store.getters["auth/getLaborExange"] === null) {
        this.$confirm(this.$t("appointments.info_system"), "", "").then(() => {
          this.$router.push({
            name: "UserSettings",
            params: { type: "alerts", system: true },
          });
        });
      } else if (
        this.$store.getters["auth/getSetting"]("policy_appointment") === null ||
        (this.$store.getters["auth/getSetting"]("policy_appointment") ===
          "link" &&
          this.$store.getters["auth/getSetting"]("link_appointment") == null) ||
        (this.$store.getters["auth/getSetting"]("policy_appointment") ===
          "page" &&
          this.$store.getters["auth/getSetting"]("page_policy_appointment") ==
            null)
      ) {
        this.$confirm(this.$t("appointments.info_policy"), "", "").then(() => {
          this.$router.push({
            name: "UserSettings",
            params: { type: "alerts", system: true },
          });
        });
      } else {
        this.$confirm(this.$t("appointments.info_timetable"), "", "").then(
          () => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "alerts", calendar: true },
            });
          }
        );
      }
    },
    statusColor(name) {
      let s = this.states.find((s) => {
        console.log(this.$t("appointments.states." + name));
        console.log(s.text);
        return this.$t("appointments.states." + name) == s.text;
      });
      if (s) return s.color;
    },
    statusAppointment(appointment) {
      if (
        (!appointment.studio_opened && this.$store.getters["auth/isStudio"]) ||
        (!appointment.tattooer_opened && this.$store.getters["auth/isTattooer"])
      )
        return {
          text: "appointments.states.request",
          color: "primary",
          delete: true,
        };
      switch (appointment.status) {
        case "help":
        case "asesoramiento":
          return {
            text: "appointments.states.help",
            color: "red",
            delete: true,
          };
        case "archived":
          return {
            text: "appointments.states.archived",
            color: "gris1",
            delete: false,
          };
        case "done": {
          return {
            text: "appointments.states.done",
            color: "green1",
            delete: null,
          };
        }
        default: {
          if (
            appointment.available_dates.find(
              (x) =>
                x.type === "appointment_day" ||
                x.type === "appointment_day_session"
            )
          ) {
            return {
              text: "appointments.states.assigned",
              color: "secondary",
              delete: true,
            };
          } else {
            return {
              text: "appointments.states.pending",
              color: "orange",
              delete: true,
            };
          }
        }
      }
    },
    del(item) {
      this.$confirm(
        this.$t(
          item.status === "inscription" ||
            item.status === "pending_inscription_studio" ||
            item.status === "pending_inscription_tattooer"
            ? "delete_ask"
            : item.status === "archived"
            ? "desarchived_ask"
            : "archived_ask"
        )
      ).then(() => {
        this.deleteOrArchivate({
          id: item.id,
          action: this.canDelete(item)
            ? "delete"
            : item.status === "archived"
            ? "dearchivate"
            : "archivate",
        }).then((data) => {
          this.$alert(
            this.$t(
              this.canDelete(item)
                ? "delete_ok"
                : item.status === "archived"
                ? "desarchived_ok"
                : "archived_ok"
            )
          );
          if (!this.statusAppointment(item).delete)
            if (item.status === "archived") this.filters.state = null;
            else this.filters.state = "archived";

          this.fetchAppointments();
        });
      });
    },
    fetchAppointmentsFilters() {
      this.getAppointmentFilters().then((filters) => {
        this.studios = filters.studios;
        this.tattooers = filters.tattooers;
        this.customers = filters.customers;
        this.dates = filters.dates;
        this.tattoo_type = filters.tattoo_type;
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchAppointments();
    }, 1000),
    openAppointment(id) {
      this.$router.push({ name: "appointmentView", params: { id } });
    },
  },
};
</script>
<style lang="sass">
#pedirCitaLink
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    #iconClose
      color: var(--v-primary-base)
      position: absolute

#appointment-list
  .internal-btn
    position: absolute
    right: -15px
  .state
    text-transform: uppercase
    .v-chip__content
      font-size: 12px
      color: black
  .pending
    color: var(--v-primary-base)
    td
      font-weight: bolder
  @media (min-width: 800px)
  .shorter
    max-width: 201px
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white

.apListItem
  height: 30px

.apListItem .v-list-item__icon .v-icon, .apListItem .svg-icon
  margin-top: -3px
@media (max-width: 570px)
  .v-menu__content:first-child
    min-width: 104px !important
    div[role="listbox"]:nth-child(1)
      min-width: 104px !important
      text-align: center
  #appointment_table

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3)
      max-width: 570px !important

#others
  overflow: hidden
  .v-list-item
    height: 40px
    margin-top: 5px
    color: var(--v-gris1-lighten2)
  .v-input__icon
    svg
      height: 15px !important
.no-line
  &.v-text-field > .v-input__control > .v-input__slot:after,
  &.v-text-field > .v-input__control > .v-input__slot:before
    visibility: hidden
#appointment_table
  td
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  td:nth-child(1),
  td:nth-child(2)
    max-width: 200px
    min-width: 150px
  td:nth-child(3),
  th:nth-child(3)
    max-width: 131px
    min-width: 131px

.selectable:hover
  background-color: var(--v-gris1-base)

.weekSelector
  .theme--dark.v-btn:hover::before
    opacity: 0
  tr
    &:hover
      td
        background-color: var(--v-primary-base)
        border-radius: 0
        border: 1px solid var(--v-primary-base)
        &:first-child
          border-top-left-radius: 50%
          border-bottom-left-radius: 50%
        &:last-child
          border-top-right-radius: 50%
          border-bottom-right-radius: 50%
        .v-btn__content
          color: black !important

    td
      border-radius: 50px

      &:first-child
        border-left: thin var(--v-primary-base) solid

      &:last-child
        border-right: thin var(--v-primary-base) solid

#time_select
  text-align: center
  .v-btn-toggle
    .v-btn
      min-width: unset
      padding-right: 25px !important
      padding-left: 25px !important
@media (max-width:1263px ) and (min-width: 600px)
  #filters-row
    >div#time_select
      max-width: 90%
</style>
